import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WarensortimentKlartextPage = () => (
  <Layout>
    <SEO title="WarensortimentKlartext" />

    <h1  id="ueberschrift">Warenangebot &emsp; Stand:&nbsp;{new Date().getFullYear()}</h1>

    <p className="note">!!!DIESER BEREICH BEFINDET SICH IM WIEDERAUFBAU!!!</p>

    <p style={{ fontSize: "1.2em", lineHeight: "1.7" }}>
          Kontakt:&ensp;
           <a href="tel:+49-9187-7081220" title="anrufen" style={{ fontSize: "1em"  }}>+49 (0) 91 87 / 70 81 220</a>
           &ensp;oder&ensp;
           <a href="mailto:bc@bastet-project.de?subject=Anfrage zum Warenangebot" title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>bc@bastet-project.de</a>
    </p>

    <p>
     Sie werden zukünftig über diese Seite
     eine Auswahl an Waren finden, welche Sie über den Laden erwerben können. <br />
     Wann das Standgewerbe für Laufkundschaft eröffnen kann,
     hängt von dem Baufortschritt und somit von der Baufirma ab.
   </p>

   <p>
     Aktuell ist der Großteil der Waren eingelagert und steht nur bedingt bzw. auf Nachfrage zum Verkauf bereit.
   </p>

  {/**Nur einige Bilder von immer vorhandenen Waren
        incl. VK-Preis(-Tabelle)?!
      Evtl. als Tooltipp-Bild anzeigen oder Childwindow

      Preisunterschied für Stand- und Reisegewerbe bedenken!
    */}

    <h2>zum Basteln</h2>
      Perlen, Pailletten, Servietten, Bausätze, Kartonagen, etc.
      <p></p>

    <h2>für Fantasy-Begeisterte</h2>
      Bücher, Figuren, Kostüme, etc.
      <p></p>

    <h2>zum Handarbeiten</h2>
      Wolle, Nadeln, Anleitungen, etc.
      <p></p>

    <h2>für (Klein-)Kinder</h2>
      Rasseln, Mobiles, Sandspielzeug, Schneespielzeug, etc.
      <p></p>

    <h2>für Rollenspieler</h2>
      Würfel, Abenteuer, Würfelboxen, Zinnfiguren, etc.
      <p></p>

    <h2>Sonstiges</h2>
      Spiele, Karten, Geschenkartikel, Dekorationsmaterial, Schreibwaren, Moderationsmaterial<br /> und vieles mehr...
      <p></p>

  </Layout>
              )

              export default WarensortimentKlartextPage
